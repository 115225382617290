<template>
  <div class="container">
    <NavTab title="编辑资料"></NavTab>
    <van-cell-group class="userInfo-box">
      <van-cell class="avater" title="头像"  is-link center >
        <template #default>
          <van-uploader ref="file" :before-read="beforeRead" :after-read="afterRead" :multiple="false" accept=".bmp, .jpg, .png, .tif, .gif, .webp">
            <van-image width="40px" height="40px" fit="cover" round :src="userInfo.avatar || defaultImg" />
          </van-uploader>
          
        </template>

      </van-cell>
      <van-cell title="昵称" :value="userInfo.nickname" is-link @click="openDiakog" />
      <van-cell title="性别" class="gender">
        <van-radio-group slot="default" v-model="gender" direction="horizontal" icon-size="16px">
          <van-radio name="2" @click="hSaveGender(2)">保密</van-radio>
          <van-radio name="1" @click="hSaveGender(1)">男</van-radio>
          <van-radio name="0" @click="hSaveGender(0)">女</van-radio>
        </van-radio-group>
      </van-cell>
    </van-cell-group>
    <van-dialog v-model="isShowName" title="修改昵称" show-cancel-button @confirm="hSaveName">
      <van-field v-model.trim="newName" />
    </van-dialog>
    <MessagePoint mesageTxt="信息修改成功" :show="ishowSucInfo"></MessagePoint>
    <MessagePoint mesageTxt="信息修改失败" :show="isshowErrInfo"></MessagePoint>
  </div>
</template>

<script>
import NavTab from '@/components/Mobile/NavTab'
import MessagePoint from '@/components/Mobile/MessagePoint'
import { completeUserLogoUrl } from '@/utils/util';
import { mapState } from 'vuex';
export default {
  name: 'EditUserInfo',
  components: {
    NavTab,
    MessagePoint
  },
  data() {
    return {
      // 控制弹层
      isShowName: false,
      ishowSucInfo: false,
      isshowErrInfo: false,
      //修改后的新名字
      newName: '',
      //选择的头像
      selectAvatar: '',
      // 默认头像
      defaultImg: require('@/assets/images/userLogo.jpg'),
      fileList:[],
      file:null
    };
  },
  computed: {
    ...mapState(['userInfo']),
    gender: {
      get() {
        return String(this.userInfo.sex);
      },
      set(newval) {
        this.userInfo.sex = newval;
      },

    }
  },
  create() {
    this.fileList.push(this.userInfo);
  },

  methods: {
    // 修改名字
    async hSaveName() {
      if (this.newName === '') {
        return
      }
      try {
        await this.$store.dispatch('updateNickName', this.newName);
        //调用成功的消息提示方法
        this.succussInfo();
      } catch (err) {
        // 调用失败的消息提示方法
        this.errorInfo();
      }
    },
    // 修改性别
    async hSaveGender(sex) {
      try {
        await this.$store.dispatch('updateSex', sex);
        //调用成功的消息提示方法
        this.succussInfo();
      } catch (error) {
        // 调用失败的消息提示方法
        this.errorInfo();
      }
    },
    // 上传图片之前
    beforeRead(file)
    {
      let reg = /.(bmp|jpg|png|gif|webp)$/;
      let flag = reg.test(file.name);
      if (!flag)
      {
        this.$toast.fail('图片上传格式错误');
      }
      return flag;
    },
   async afterRead(file)
    {
      // console.log(file);
     let fileObj = file.file;
     console.log(file);
     let formData = new FormData();
     formData.append('file',fileObj);
     let res = await this.api
          .postFormAPISM(
            formData,
            "/article/imageuploader"
          )
      let avatar =   completeUserLogoUrl(res.data.url)
    try {
      await  this.$store.dispatch('updateAvater',avatar );
      this.succussInfo();
    } catch (error) {
        this.errorInfo();
    }
    },
    openDiakog()
    {
      this.isShowName = true;
      this.newName = this.userInfo.nickname;
    },
    //成功的消息提示
    succussInfo() {
      this.ishowSucInfo = true;
      setTimeout(() => {
        this.ishowSucInfo = false;
      }, 500);
    },
    //失败的消息提示
    errorInfo() {
      this.isshowErrInfo = true;
      setTimeout(() => {
        this.isshowErrInfo = false;
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>